import * as Yup from 'yup';
import reduce from 'lodash/reduce';

const matchesPresets = {
  numbers: [/^\d+$/, '只能輸入數字'],
};

const schemaTransformers = {
  matchesPreset: (name) => ({
    key: 'matches',
    params: matchesPresets[name],
  }),
  when: ([{ name, is, then }], ns, yupType) => ({
    key: 'when',
    params: [
      String(name).split(',').map((n) => `${ns}-${n}`),
      {
        is: (...values) => values.reduce((all, v) => all || v == is, false),
        then: then.reduce((res, cfg, index) => {
          if (index) {
            return res[cfg.key](cfg.value || undefined);
          } else {
            if (cfg.key === 'type') {
              return Yup[cfg.type]();
            } else {
              return yupType[cfg.key](cfg.value || undefined);
            }
          }
        }, null)
      }
    ]
  }),
};

const moduleTypes = {
  choice: 'number',
  'choice-multiple': 'array',
  matrix: 'string',
  input: 'string',
  'text-on-pic': 'string',
  sort: 'array',
};

Yup.setLocale({
  mixed: {
    required: '此題必填'
  },
  string: {
    email: 'E-mail格式錯誤~',
    min: '長度要大於或等於${min}',
    max: '長度要小於或等於${max}',
  },
  number: {
    default: '只能輸入數字喔',
    integer: '只能輸入「整數」',
  },
  array: {
    min: '至少要包含${min}個項目',
    max: '不可超過${max}個項目',
  }
})

const getSchema = (type, setting, module, namespace) => {
  const yupType = (Yup[type] || Yup[moduleTypes[module]] || Yup.mixed)();
  return reduce(setting, (schema, value, key) => {
    if (!value) return schema;
    const transformer = schemaTransformers[key] && schemaTransformers[key](value, namespace, yupType);
    const method = transformer ? schema[transformer.key] : schema[key];
    const params = transformer
      ? transformer.params
      : (value === true ? undefined : value);
    return method.apply(schema, [].concat(params));
  }, yupType)
};

export default getSchema;
