import React, { PureComponent, createElement } from 'react';
import { graphql, navigate } from 'gatsby'
import get from 'lodash/get';
import merge from 'lodash/merge';
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'
import { object } from 'yup';
import { Formik } from 'formik';

import Container from '../../components/Container';
import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Progress from '../../components/Progress';
import Text from '../../components/Text';

import theme from '../../components/ThemeProvider/theme';

import Form from './Form';

import { withConnect, ReduxContext } from '../../stores/surveyStore';
import getSchema from './getSchema';

const setSubFileds = (config, fields, { schema, name }) => {
  fields.forEach((dim, dimIndex) => {
    const subName = `${name}-${dimIndex}`;
    config.validationSchema[subName] = schema;
    config.initialValues[subName] = undefined;
  });
}

class Questions extends PureComponent {
  constructor(props) {
    super(props);
    const { data: { allSurvey }, pageContext: { index }, answers } = props;
    const modules = allSurvey.edges[0].node.modules;
    const { validationSchema, initialValues } = modules.reduce((initialConfig, {
      module,
      dimensions,
      texts,
      multiple,
      schema: config,
    }, key) => {
      const name = `${index}-${key}`;
      if (!config) return initialConfig;
      const { type, ...sc } = config;
      const schema = getSchema(type, sc, `${module}${multiple ? '-multiple': ''}`, index);
      if (module === 'matrix') {
        setSubFileds(initialConfig, dimensions, { name, schema });
      } else if (module === 'text-on-pic') {
        setSubFileds(initialConfig, texts.boxes, { name, schema });
      } else {
        initialConfig.validationSchema[name] = schema;
        initialConfig.initialValues[name] = undefined;
      }
      return initialConfig;
    }, { validationSchema: {}, initialValues: {} });

    this.validationSchema = object().shape(validationSchema);
    this.initialValues = answers ? merge(initialValues, answers.toJS()) : initialValues;
  }

  handleSubmit = (values, { setSubmitting }) => {
    const {
      sectionFinished,
      setCompleted,
      pageContext: {
        slug,
        index,
        isLast
      },
    } = this.props;
    setSubmitting(true);
    sectionFinished({ slug, section: index });
    if (isLast) {
      setCompleted({ slug });
    }
    navigate(`/${[slug, isLast ? 'thanks' : (index + 2)].join('/')}/`)
  }

  render() {
    const {
      data: { allSurvey },
      pageContext: { index, isLast, slug, total, offset },
      setOtherValue,
      others,
      finishedSection,
      setAnswer,
      setTimer,
      isMobile,
      timers,
    } = this.props;
    if (process.env.NODE_ENV === 'production'
      && typeof window !== 'undefined'
      && ((index > 0 && finishedSection === undefined) || index - finishedSection > 1)
    ) {
      navigate(`/${slug}/${index}`, { replace: true });
      return null;
    }

    const pageData = get(allSurvey, ['edges', 0, 'node']);
    let { headerHeight } = theme;
    const hasProgess = total > 1;
    if (!hasProgess) headerHeight = '0px'
    return (
      <ReduxContext.Provider
        value={{
          setOtherValue: (payload) => setOtherValue({ ...payload, slug }),
          setTimer: (payload) => setTimer({ ...payload, slug }),
          timers: timers ? timers.toJS() : {},
          others,
        }}
      >
        <Box bg="variations.gray.1">
          {hasProgess && (
            <Box
              position="fixed"
              top="0"
              left="0"
              right="0"
              bg="white"
              height={headerHeight}
              zOrder={2}
              borderBottom="1px solid"
              borderColor="variations.gray.2"
            >
              <Container height="100%">
                <Flex height="100%" alignItems="center">
                  <Progress width="100%" total={total} current={index + 1} />
                </Flex>
              </Container>
            </Box>
          )}
          <Container pt={headerHeight} bg="white">
            {pageData.title && (
              <Box
                borderBottom="3px solid"
                borderColor="variations.gray.2"
                py="1em"
              >
                <Text.h2
                  color="blue"
                  whiteSpace="pre-wrap"
                  lineHeight="1.75"
                  mb="0.5em"
                >{pageData.title}</Text.h2>
                <Text.h4
                  color="variations.gray.7"
                  whiteSpace="pre-wrap"
                >{pageData.subtitle}</Text.h4>
              </Box>
            )}
          </Container>
          <Container pb="2rem" bg="white" position="relative" px="0" pt="0.5em">
            <Formik
              validationSchema={this.validationSchema}
              initialValues={this.initialValues}
              onSubmit={this.handleSubmit}
            >
              {(formikProps) => (
                <Form
                  {...formikProps}
                  validationSchema={this.validationSchema}
                  modules={pageData.modules}
                  pageIndex={index}
                  isLastPage={isLast}
                  hideButton={pageData.hideButton}
                  gap={pageData.gap || '4rem'}
                  onSetAnswer={(values) => setAnswer({ slug, values })}
                  offset={offset}
                  isAlter={finishedSection >= index}
                  isMobile={isMobile}
                  hasProgess={hasProgess}
                  preferHintRequired={pageData.preferHintRequired}
                />
              )}
            </Formik>
          </Container>
        </Box>
      </ReduxContext.Provider>
    )
  }
}

export default DragDropContext(HTML5Backend)((props) => createElement(withConnect(props.pageContext.slug)(Questions), props));

export const query = graphql`
  query($slug: String!, $type: String!, $index: Int) {
    allSurvey(filter: { type: { eq: $type }, slug: { eq: $slug } }, limit: 1, skip: $index) {
      edges {
        node {
          slug
          title
          subtitle
          hideButton
          preferHintRequired
          gap
          modules {
            module
            src
            content
            snap
            timer
            lock
            multiple
            label
            dimensions
            perRow
            hint
            textarea
            degrees
            min
            max
            leftLabel
            rightLabel
            title
            ordered
            collapsible
            list {
              label
              description
            }
            options {
              label
              src
              special
            }
            decisions {
              src
            }
            schema {
              type
              email
              matchesPreset
              min
              max
              integer
              required
              when {
                name
                is
                then {
                  key
                }
              }
            }
            texts {
              width
              height
              boxes {
                x
                y
                width
                height
                fontSize
                fontWeight
                textAlign
                color
                multiLine
              }
            }
          }
        }
      }
    }
  }
`
